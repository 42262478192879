import React, { useEffect } from "react";
import { Seo } from "../SEO/Seo.jsx";
import CTABanner from "../CTABanner/CTABanner";

import MainMenu from "../MainMenu/MainMenu";
import Footer from "../Footer/Footer.jsx";
import HeroSection from "./components/HeroSection.jsx";
import ContactFormSection from "./components/ContactFormSection.jsx";
import EmailSection from "./components/EmailSection.jsx";

import AOS from "aos";
import "aos/dist/aos.css";

const Contact = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    <main>
      <Seo
        title="Contact us | Gammasoft: Custom Software Development Company"
        description="Get in touch with our company"
      />
      <MainMenu />
      <HeroSection />
      <ContactFormSection />
      <EmailSection />
      <CTABanner />
      <Footer />
    </main>
  );
};

export default Contact;

import React from "react";

import RectangleImg from "../../../images/rectangle.svg";
import SkewRectangleHeroImg from "../../../images/skewRectangleHero.png";

const HeroSection = () => {
  return (
    <section
      className="contact-hero"
      style={{ backgroundImage: `url(${SkewRectangleHeroImg})` }}
    >
      <div className="contact-hero-content container">
        <div className="contact-hero-content-left" data-aos="fade-up">
          <h1 className="heading-primary">
            We are always looking for exciting projects
          </h1>
          <p className="paragraph-primary">
            Our company is dedicated to delivering top-notch assistance to our
            clients. Whether you require aid with a straightforward assignment
            or a multifaceted undertaking, our team is available to assist you
            at every stage of the process.
          </p>
          <p className="paragraph-primary">
            Subsequently, we will collaborate with you to devise a tailored
            course of action that caters to your requirements and facilitates
            the attainment of your goals.
          </p>
        </div>
        <div className="contact-hero-content-right" data-aos="fade-left">
          <img src={RectangleImg} alt="" />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

import React, { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import emailjs from "@emailjs/browser";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const ContactFormSection = () => {
  const form = useRef();
  const [submitted, setSubmitted] = useState(false);
  const [answer, setAnswer] = useState("");
  const [equation, setEquation] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  function generateEquation() {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    const operator = Math.random() < 1 ? "+" : "-";
    const result = operator === "+" ? num1 + num2 : num1 - num2;
    const equation = `${num1} ${operator} ${num2} = `;
    setEquation(equation);
    setAnswer(result);
  }

  const schema = yup.object().shape({
    name: yup.string().required(),
    subject: yup.string().required(),
    email: yup.string().email().required(),
    company_name: yup.string(),
    message: yup.string().required(),
    selectTc: yup.bool().oneOf([true], "Please Select Term and Conditions"),
    answer: yup.number().required("Answer is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data, event) => {
    setIsLoading(true);
    if (answer === data.answer) {
      try {
        await emailjs.sendForm(
          `${process.env.GATSBY_SERVICE_KEY}`,
          `${process.env.GATSBY_TEMPLATE_KEY}`,
          form.current,
          `${process.env.GATSBY_EMAIL_KEY}`
        );
        setSubmitted(true);
        reset();
        generateEquation();
      } catch (error) {
        console.log(error.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      event.preventDefault();
      setErrorMessage("Incorrect answer");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    generateEquation();
  }, []);

  const showForm = (
    <form onSubmit={handleSubmit(onSubmit)} method="post" ref={form}>
      <div className="form-inputs">
        <label
          htmlFor="name"
          className="name"
          data-aos="fade-up"
          data-aos-delay={50}
        >
          <h5 className="heading-fifth">Name</h5>
          <input
            type="text"
            id="name"
            {...register("name")}
            placeholder="Your name"
            maxLength="128"
          />
          <p> {errors?.name?.message} </p>
        </label>

        <label
          htmlFor="subject"
          className="subject"
          data-aos="fade-up"
          data-aos-delay={100}
        >
          <h5 className="heading-fifth">Subject</h5>
          <input
            type="text"
            id="subject"
            {...register("subject")}
            placeholder="Subject"
            maxLength="128"
          />
          <p> {errors?.subject?.message} </p>
        </label>

        <label
          htmlFor="email"
          className="email"
          data-aos="fade-up"
          data-aos-delay={150}
        >
          <h5 className="heading-fifth">Email</h5>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="your@email.address"
            {...register("email")}
            maxLength="128"
          />
          <p> {errors?.email?.message} </p>
        </label>

        <label
          htmlFor="company_name"
          className="company_name"
          data-aos="fade-up"
          data-aos-delay={200}
        >
          <h5 className="heading-fifth">Company name</h5>
          <input
            type="text"
            name="company_name"
            id="company_name"
            placeholder="Company Name"
            maxLength="128"
            {...register("company_name")}
          />
          <p>This field is optional</p>
        </label>

        <label
          htmlFor="message"
          className="message"
          data-aos="fade-up"
          data-aos-delay={250}
        >
          <h5 className="heading-fifth">Message</h5>
          <textarea
            type="text"
            name="message"
            id="message"
            rows="1"
            cols="10"
            placeholder="Your message..."
            {...register("message")}
          />
          <p> {errors?.message?.message} </p>
        </label>
      </div>
      <div className="answer-group" data-aos="fade-up" data-aos-delay={250}>
        <p className="siema">{equation}</p>
        <input
          type="number"
          {...register("answer")}
          placeholder="Result"
          maxLength="128"
          className="answer"
        />
      </div>
      <p className="invalid-feedback">{errors?.answer?.message}</p>
      <p className="invalid-feedback">{errorMessage}</p>
      <div className="error-block" />
      <div className="form-group" data-aos="fade-up">
        <div className="form-check">
          <input
            type="checkbox"
            name="selectTc"
            {...register("selectTc")}
            id="selectTc"
            className={`form-check-input ${
              errors.selectTc ? "is-invalid" : ""
            }`}
          />

          <label htmlFor="selectTc" className="paragraph-primary">
            I agree to the processing of my personal data by GammaSoft for
            contact purposes.
          </label>
        </div>
        <div className="submit-wrapper">
          <button
            type="submit"
            className="btn btn-primary"
            disabled={isLoading}
          >
            {isLoading ? "Sending..." : "Send message"}
          </button>
        </div>
      </div>
      <p className="invalid-feedback">{errors?.selectTc?.message}</p>
    </form>
  );

  return (
    <section className="contact-form-section">
      <div className="contact-form-section-content container">
        <h2 className="heading-secondary" data-aos="fade-up">
          Request for information
        </h2>
        <p className="paragraph-primary" data-aos="fade-up" data-aos-delay={50}>
          You are taking a decisive step in realizing your aim! Before our first
          communication, what would you like us to know about your project?
          Please provide a brief description.
        </p>
      </div>
      <div className="contact-form-section-wrapper container">{showForm}</div>
      {submitted ? (
        <div className="msg-confirm">
          <p>Your message has been sent</p>
        </div>
      ) : null}
    </section>
  );
};

export default ContactFormSection;

import React from "react";

const EmailSection = () => {
  return (
    <section className="email-section">
      <div className="email-section-content container">
        <div
          className="email-section-content-item"
          data-aos="fade-up"
          data-aos-delay={150}
        >
          <p className="paragraph-primary">Want to work with us?</p>
          <a href="mailto: jobs@gammasoft.pl" className="">
            jobs@gammasoft.pl
          </a>
        </div>
        <div
          className="email-section-content-item"
          data-aos="fade-up"
          data-aos-delay={250}
        >
          <p className="paragraph-primary">Everything else.</p>
          <a href="mailto: contact@gammasoft.pl" className="">
            contact@gammasoft.pl
          </a>
        </div>
      </div>
    </section>
  );
};

export default EmailSection;

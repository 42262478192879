import * as React from "react";

import "../styles/styles.scss";

import Contact from "../components/Contact/Contact.jsx";

const IndexPage = () => {
  return <Contact />;
};

export default IndexPage;
